import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/products.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BiEditAlt } from "react-icons/bi";
import { MdLocalHospital } from "react-icons/md";
import TablePagination from "@material-ui/core/TablePagination";
import Global from "../global";
// import { IoSearchOutline } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
// import { AiOutlineCloudDownload } from "react-icons/ai";
import { MdAdd } from "react-icons/md";

import { IconContext } from "react-icons";
import Select from "react-select";
// import { MdKeyboardBackspace } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
// import API from "../utills/api";
import Loader from "react-loader-spinner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import emptyUser from "../assets/images/empty-user.png";
import moment from "moment";
import csc from "country-state-city";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router";

import {
  MuiPickersUtilsProvider,
  //   KeyboardTimePicker,
  //   KeyboardDatePicker,
  DatePicker,
  //   KeyboardDateTimePicker,
} from "@material-ui/pickers";
const statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

const genderOptions = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  { value: "Other", label: "Other" },
];

// add button icon alignment design
const useStyles = makeStyles({
  rootAbsolute: {
    position: "relative",
    fontFamily: "BoldFont",

    "& .MuiButton-startIcon": {
      position: "absolute",
      right: 15,
    },
    "& .MuiButton-label": {
      marginRight: 10,
      transition: "all 0.3s",
    },
  },
});

const Users = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  // console.log("AdminProduct", props);
  //   const [period, setPeriod] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [countries, setCountries] = useState([{ name: "Choose Country" }]);
  const [allUsers, setAllUsers] = useState([]);
  const [userData, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userAccess, setUserAccess] = useState({});
  // console.log("loading", loading);
  const [refresh, setRefresh] = useState("");

  const [editUser, setEdit] = useState({
    data: {},
    index: "",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // table pagination handlechange for number of pages
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    const total = allUsers.length;
    let startIndex = (newPage + 1) * rowsPerPage - rowsPerPage;

    const endIndex = startIndex + rowsPerPage;
    const data = allUsers.slice(startIndex, endIndex);

    setUsers([...data]);
  };

  // number of rows handle change for items display
  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    let startIndex = 1 * event.target.value - event.target.value;

    const endIndex = startIndex + event.target.value;
    const data = allUsers.slice(startIndex, endIndex);

    setUsers([...data]);
  };

  useEffect(() => {
    const country = csc.getAllCountries();
    async function fetchMembers() {
      try {
        const checkUser = await axios.post(
          Global.base_url + "/users/check/privileges",
          null,
          config
        );

        if (checkUser.status === 200) {
          console.log("checkUser", checkUser);
          setUserAccess(checkUser.data);
          if (
            _.some(checkUser.data.privileges, ["privilege_name", "VIEW USERS"])
          ) {
            try {
              const response = await axios.post(
                Global.base_url + "/users",
                null,
                config
              );

              // console.log(response);

              if (response.status === 200) {
                // setLoading(false);

                console.log("user response", response);
                let datatest = response.data.data;

                // fetching pagination count and items display after delete and modification of data
                const total = datatest.length;
                let startIndex = (page + 1) * rowsPerPage - rowsPerPage;

                const endIndex = startIndex + rowsPerPage;
                const data = datatest.slice(startIndex, endIndex);
                setCountries([...countries, ...country]);
                // console.log("test response: ", response);
                setAllUsers(response.data.data);
                setUsers(data);
              }
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  return toast.error(error.response.data.msg);
                }
                if (error.response.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }

            return setLoading(false);
          }
        }
      } catch (error) {
        if (error.checkUser) {
          if (error.checkUser.status === 401) {
            return toast.error("Unable to get member access");
          }
          if (error.checkUser.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
    }
    fetchMembers();
  }, [refresh]);

  // const classfilter=open?"d-flex flex-column "
  const rowdataAlign = { verticalAlign: "middle" };

  const getEditData = async (data, i) => {
    console.log("edit data", [data, i]);
    try {
      const editResponse = await axios.post(
        Global.base_url + "/users/userbyID",
        {
          user_id: data.user_id,
        },
        config
      );
      // console.log(response);

      if (editResponse.status === 200) {
        // setLoading(false);

        console.log("editResponse", editResponse);
        // return;
        setEdit({
          data: editResponse.data.data[0],
          index: i,
        });
        // props.setUserss(data);
      }
      // return setLoading(false);
    } catch (error) {
      if (error.editResponse) {
        if (error.editResponse.status === 401) {
          return toast.error("Unable to get edit roles");
        }
        if (error.editResponse.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };

  console.log("editUser", editUser);

  // initial values for input fields
  const initialValues =
    // condition for add or edit modal
    // editUser index indicates to existing products
    editUser.index !== ""
      ? {
          user_id: editUser.data.user_id,
          firstname: editUser.data.first_name,
          lastname: editUser.data.last_name,
          email: editUser.data.email_id,
          gender: {
            label: `${editUser.data.gender}`,
            value: editUser.data.gender,
          },

          status: {
            label: editUser.data.user_status ? "Active" : "Inactive",
            value: editUser.data.user_status,
          },
          country: {
            label: editUser.data.country,
            value: editUser.data.country,
          },
          state: {
            label: editUser.data.state,
            value: editUser.data.state,
          },
          city: {
            label: editUser.data.district,
            value: editUser.data.district,
          },
          phone: editUser.data.phone_no,
          address: editUser.data.address,
          pincode: editUser.data.pincode,
          dob: moment(editUser.data.dob, "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'").format(
            "YYYY-MM-DD"
          ),
        }
      : {
          // product_id: "",
          firstname: "",
          lastname: "",
          email: "",
          gender: null,
          address: "",
          country: null,
          state: null,
          city: null,
          dob: "",
          phone: "",
          pincode: "",
          status: null,
          //   product_yellowcolortest: "",
        };

  function MyVerticallyCenteredModal({
    editUser,
    setRefresh,
    initialValues,
    handleModal,
    setEdit,
    modalStatus,
  }) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const config2 = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const [states, setStates] = useState([{ name: "Choose State" }]);
    const [cities, setCities] = useState([{ name: "Choose City" }]);
    const [uploader, setUploader] = useState(false);
    const [resetLoader, setResetLoader] = useState(false);

    const getAllStates = (e) => {
      // const noOption = { label: "Choose State", value: "" };
      console.log(e);
      const country = countries.find((c) => c.name === e);
      console.log({ country });
      setStates([...csc.getStatesOfCountry(country.isoCode)]);
    };
    const getAllCities = (e) => {
      // const noOption = { label: "Choose City", value: "" };
      const state = states.find((s) => s.name === e);

      setCities([...csc.getCitiesOfState(state.countryCode, state.isoCode)]);
    };
    const required = <span style={{ color: "red" }}>*</span>;
    console.log("editUser", editUser);
    function closeModal() {
      setEdit({
        data: "",
        index: "",
      });
      setShowEditModal(false);
    }
    const deleteProduct = async () => {
      try {
        const response = await axios.post(
          Global.base_url + "/users/delete",
          {
            user_id: editUser.data.user_id,
          },
          config2
        );

        // console.log(response);

        if (response.status === 200) {
          await closeModal();
          toast.success("User Deleted Successfully");

          return setRefresh(Date.now() + 1);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("User Deletion failed");
        }

        if (error.response && error.response.status === 500) {
          toast.error("Internal server error");
        }
        // return console.log("admin delete fetch error", error);
      }
    };

    const resetPassword = async (user_id) => {
      setResetLoader(true);
      try {
        const response = await axios.post(
          Global.base_url + "/users/resetPassword",
          {
            user_id: user_id,
          },
          config2
        );

        // console.log(response);

        if (response.status === 200) {
          setResetLoader(false);
          await closeModal();
          toast.success("Reset link sent to E-mail");

          return setRefresh(Date.now() + 1);
        }
      } catch (error) {
        setResetLoader(false);
        if (error.response && error.response.status === 401) {
          toast.error("Reset failed");
        }

        if (error.response && error.response.status === 500) {
          toast.error("Internal server error");
        }
        // return console.log("admin delete fetch error", error);
      }
    };

    return (
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="boldFont">
              {editUser.index !== "" ? "Edit User" : "Add User"}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={
              editUser.index !== ""
                ? yup.object().shape({
                    firstname: yup
                      .string()
                      .required("Firstname cannot be empty"),
                    lastname: yup.string().required("Lastname cannot be empty"),
                    address: yup.string().required("Address cannot be empty"),
                    gender: yup
                      .object()
                      .required("Please select gender")
                      .nullable(),
                    email: yup
                      .string()
                      .email("Invalid Email")
                      .required("Email cannot be empty"),
                    status: yup
                      .object()
                      .required("Please select status")
                      .nullable(),
                    country: yup
                      .object()
                      .required("Please select country")
                      .nullable(),
                    state: yup
                      .object()
                      .required("Please select state")
                      .nullable(),
                    city: yup
                      .object()
                      .required("Please select city")
                      .nullable(),
                    dob: yup
                      .date()
                      .min("1925-01-01", "Date must be later than 01-01-1925")
                      .max("2018-12-30", "Date must be earlier than 01-01-2018")
                      .required("DOB is required")
                      .nullable(),
                    phone: yup
                      .string()
                      .phone()
                      .required("Phone cannot be empty"),
                    pincode: yup.string().required("Pincode cannot be empty"),
                  })
                : yup.object().shape({
                    firstname: yup
                      .string()
                      .required("Firstname cannot be empty"),
                    lastname: yup.string().required("Lastname cannot be empty"),
                    address: yup.string().required("Address cannot be empty"),
                    gender: yup
                      .object()
                      .required("Please select gender")
                      .nullable(),
                    email: yup
                      .string()
                      .email("Invalid Email")
                      .required("Email cannot be empty"),
                    country: yup
                      .object()
                      .required("Please select country")
                      .nullable(),
                    state: yup
                      .object()
                      .required("Please select state")
                      .nullable(),
                    city: yup
                      .object()
                      .required("Please select city")
                      .nullable(),
                    dob: yup
                      .date()
                      .min("1925-01-01", "Date must be later than 01-01-1925")
                      .max("2018-12-30", "Date must be earlier than 01-01-2018")
                      .required("DOB is required")
                      .nullable(),
                    phone: yup
                      .string()
                      .phone()
                      .required("Phone cannot be empty"),
                    pincode: yup.string().required("Pincode cannot be empty"),
                  })
            }
            onSubmit={async (values, formik) => {
              console.log("values", values);

              //   return;
              setUploader(true);
              // console.log(values);
              const url =
                editUser.index !== ""
                  ? Global.base_url + "/users/update"
                  : Global.base_url + "/users/add";

              const data =
                editUser.index !== ""
                  ? {
                      ...values,
                      user_id: editUser.data.user_id,
                      gender: values.gender.value,
                      country: values.country.value,
                      state: values.state.value,
                      city: values.city.value,
                      dob: moment(values.dob).format("YYYY-MM-DD"),
                      status: values.status.value,
                    }
                  : {
                      ...values,
                      gender: values.gender.value,
                      country: values.country.value,
                      state: values.state.value,
                      city: values.city.value,
                      dob: moment(values.dob).format("YYYY-MM-DD"),
                    };

              console.log("user send datas", data);
              //   return;
              try {
                const response = await axios.post(url, data, config2);
                // console.log(response);
                if (response.status === 200) {
                  setUploader(false);
                  await closeModal();
                  editUser.index !== ""
                    ? toast.success("User Updated Successfully")
                    : toast.success("User Added Successfully");
                  //   formik.resetForm();
                  return setRefresh(Date.now() + 1);
                }
              } catch (error) {
                if (error.response && error.response.status === 401) {
                  toast.error(error.response.data.msg);

                  return setUploader(false);
                }
                if (error.response && error.response.status === 500) {
                  toast.error("Internal server error");
                  return setUploader(false);
                }
              }
            }}
          >
            {(formik) => {
              console.log("errors", formik.errors);
              return (
                <>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="first-name"
                            className="semiFont login__label"
                          >
                            First Name {required}
                          </label>
                          <input
                            type="text"
                            id="first-name"
                            placeholder="First Name"
                            // className="form-control signup__input"
                            value={formik.values.firstname}
                            style={{
                              border: formik.errors.firstname
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                            }}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "firstname",
                                e.currentTarget.value.replace(
                                  /[^a-zA-Z0-9 ]/g,
                                  ""
                                )
                              );
                            }}
                          />
                          {formik.errors.firstname && (
                            <div className="err">
                              <p>{formik.errors.firstname}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="last-name"
                            className="semiFont login__label"
                          >
                            Last Name {required}
                          </label>
                          <input
                            type="text"
                            className="form-control addproduct--input"
                            id="last-name"
                            placeholder="Last Name"
                            value={formik.values.lastname}
                            style={{
                              border: formik.errors.lastname
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                            }}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "lastname",
                                e.currentTarget.value.replace(
                                  /[^a-zA-Z0-9 ]/g,
                                  ""
                                )
                              );
                            }}
                          />
                          {formik.errors.lastname && (
                            <div className="err">
                              <p>{formik.errors.lastname}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <div className="label__row d-flex align-items-center justify-content-between">
                            <label
                              htmlFor="Email"
                              className="semiFont login__label"
                            >
                              Email {required}
                            </label>
                            {editUser.index !== "" ? (
                              <p
                                className="member__status semiFont"
                                style={{
                                  color: editUser.data.completed
                                    ? "#03A33B"
                                    : "#ffb100",
                                }}
                              >
                                {editUser.data.completed
                                  ? "Activated"
                                  : "Pending"}
                              </p>
                            ) : null}
                          </div>
                          <input
                            type="email"
                            id="Email"
                            placeholder="Email"
                            // className="form-control signup__input"
                            value={formik.values.email}
                            style={{
                              border: formik.errors.email
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                            }}
                            onChange={formik.handleChange("email")}
                          />
                          {formik.errors.email && (
                            <div className="err">
                              <p>{formik.errors.email}</p>
                            </div>
                          )}
                        </div>
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="gender"
                            className="semiFont login__label"
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            Gender {required}
                          </label>
                          <Select
                            value={formik.values.gender}
                            onChange={(p) => {
                              formik.setFieldValue("gender", p);
                            }}
                            id="inputDropdown"
                            placeholder="Select Gender"
                            options={genderOptions}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "8px",
                                border: formik.errors.gender
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                            className="admin__Addproduct--dropdown "
                          />
                          {formik.errors.gender && (
                            <div className="err">
                              <p>{formik.errors.gender}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="address"
                            className="semiFont login__label"
                          >
                            Address{required}
                          </label>
                          <textarea
                            id="address"
                            rows="5"
                            style={{
                              border: formik.errors.address
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                              resize: "none",
                              borderRadius: "8px",
                              padding: "10px",
                              color: "#183b56",
                              fontSize: "17px",
                              fontFamily: "mediumFont",
                            }}
                            placeholder="Address"
                            value={formik.values.address}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "address",
                                e.currentTarget.value.replace(
                                  /[^a-zA-Z0-9-_+.,/' ]/g,
                                  ""
                                )
                              );
                            }}
                          ></textarea>
                          {formik.errors.address && (
                            <div className="err">
                              <p>{formik.errors.address}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="dob"
                            className="semiFont login__label"
                          >
                            Date of Birth {required}
                          </label>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              //   inputVariant="filled"
                              disableFuture
                              openTo="year"
                              //   className={classes.root}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              // minDate={moment("01-01-1920", "DD-MM-YYYY")}
                              // maxDate={moment("01-01-2010", "DD-MM-YYYY")}
                              format="DD/MM/YYYY"
                              views={["year", "month", "date"]}
                              value={formik.values.dob}
                              onChange={(date) =>
                                formik.setFieldValue("dob", date)
                              }
                              style={{
                                border: formik.errors
                                  ? formik.errors.dob
                                    ? "1px solid #dc3545"
                                    : "1px solid #c3cad9"
                                  : "1px solid #c3cad9",
                                borderRadius: "8px",
                                marginTop: "5px",
                                padding: "2px",
                              }}
                              error={false}
                              helperText={null}
                              placeholder="DD/MM/YYYY"
                            />
                          </MuiPickersUtilsProvider>
                          {formik.errors.dob && (
                            <p className="err">{formik.errors.dob}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="phone"
                            className="semiFont login__label"
                          >
                            Phone {required}
                          </label>
                          <input
                            type="tel"
                            value={formik.values.phone}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "phone",
                                e.currentTarget.value.replace(/[^0-9]/g, "")
                              )
                            }
                            style={{
                              border: formik.errors.phone
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                            }}
                            id="phone"
                            aria-describedby="phone"
                            placeholder="Phone Number"
                          />
                          {formik.errors.phone && (
                            <p className="err">{formik.errors.phone}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="country"
                            className="semiFont login__label"
                          >
                            Country {required}
                          </label>
                          <Select
                            value={formik.values.country}
                            onChange={(p) => {
                              getAllStates(p.value);
                              formik.setFieldValue("country", p);
                              formik.setFieldValue("state", null);
                              formik.setFieldValue("city", null);
                            }}
                            // defaultValue=""
                            options={countries.map((c) => {
                              return { value: c.name, label: c.name };
                            })}
                            id="inputDropdown"
                            name="country"
                            className="admim__Addproduct--dropdown "
                            placeholder="Select Country"
                            // id="state"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "10px 10px",
                                border: formik.errors.country
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                          />

                          {formik.errors.country && (
                            <div className="err">
                              <p>{formik.errors.country}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                          id="a123"
                        >
                          <label
                            htmlFor="state"
                            className="semiFont login__label"
                          >
                            State {required}
                          </label>
                          <Select
                            value={formik.values.state}
                            onChange={(p) => {
                              getAllCities(p.value);
                              formik.setFieldValue("state", p);
                              formik.setFieldValue("city", null);
                            }}
                            options={states.map((s) => {
                              return { value: s.name, label: s.name };
                            })}
                            // defaultValue=""
                            id="inputDropdown"
                            className="admim__Addproduct--dropdown "
                            placeholder="Select State"
                            styles={{
                              control: (base, baseState) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "10px 10px",
                                border: formik.errors.state
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                          />

                          {formik.errors.state && (
                            <div className="err">
                              <p>{formik.errors.state}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                          id="a123"
                        >
                          <label
                            htmlFor="product-status"
                            className="semiFont login__label"
                          >
                            City {required}
                          </label>
                          <Select
                            value={formik.values.city}
                            onChange={(p) => {
                              formik.setFieldValue("city", p);
                            }}
                            options={cities.map((c) => {
                              return { value: c.name, label: c.name };
                            })}
                            // defaultValue=""
                            className="admim__Addproduct--dropdown "
                            placeholder="Select City"
                            id="inputDropdown"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "10px 10px",
                                border: formik.errors.city
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                          />

                          {formik.errors.city && (
                            <div className="err">
                              <p>{formik.errors.city}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="pincode"
                            className="semiFont login__label"
                          >
                            Postal code / ZIP {required}
                          </label>
                          <input
                            type="text"
                            value={formik.values.pincode}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "pincode",
                                e.currentTarget.value.replace(/[^\w\s]/gi, "")
                              )
                            }
                            style={{
                              border: formik.errors.pincode
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                              fontFamily: "mediumFont",
                            }}
                            id="pincode"
                            placeholder="Postal code"
                            aria-describedby="pincode"
                            // placeholder=""
                          />
                          {formik.errors.pincode && (
                            <p className="err">{formik.errors.pincode}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {editUser.index !== "" ? (
                      <div className="row">
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="Status"
                              className="semiFont login__label"
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              Status{required}
                            </label>
                            <Select
                              value={formik.values.status}
                              name="Status"
                              onChange={(p) => {
                                formik.setFieldValue("status", p);
                                // console.log("type ", p.value);
                              }}
                              id="inputDropdown"
                              placeholder="Select Status"
                              options={statusOptions}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "10px 10px",
                                  border: formik.errors.status
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isFocused
                                    ? "#183b56"
                                    : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                              className="admin__Addproduct--dropdown "
                            />
                            {formik.errors.status && (
                              <div className="err">
                                <p>{formik.errors.status}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div
                      className={
                        editUser.index !== "" &&
                        userAccess &&
                        _.some(userAccess.privileges, [
                          "privilege_name",
                          "DELETE USERS",
                        ])
                          ? "mt-3 d-flex flex-column flex-sm-row justify-content-between"
                          : "mt-3 d-flex flex-column flex-sm-row justify-content-end"
                      }
                    >
                      {editUser.index !== "" &&
                      userAccess &&
                      _.some(userAccess.privileges, [
                        "privilege_name",
                        "DELETE USERS",
                      ]) ? (
                        <div className="form-group  addproduct__button--align">
                          <Button
                            color="secondary"
                            onClick={deleteProduct}
                            style={{
                              backgroundColor: "#f50057",
                              color: "#fff",
                              padding: "10px 15px",
                              textTransform: "initial",
                            }}
                            disabled={editUser.index !== "" ? false : true}
                          >
                            Delete User
                          </Button>
                        </div>
                      ) : null}
                      <div className="d-flex flex-sm-row addproduct__button--aligncenter">
                        {editUser.index !== "" ? (
                          <div className="form-group mr-2">
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#fff",
                                color: "#1565d8",
                                padding: resetLoader ? "6px 40px" : "10px 15px",
                                // width: "100px",
                                textTransform: "initial",
                              }}
                              disabled={resetLoader}
                              onClick={() => {
                                resetPassword(editUser.data.user_id);
                              }}
                            >
                              {resetLoader ? (
                                <div
                                  className="spinner-border text-primary"
                                  // style={{}}
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Reset Password"
                              )}
                            </Button>
                          </div>
                        ) : null}
                        <div className="form-group ">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={formik.handleSubmit}
                            disabled={!formik.dirty || uploader}
                            style={{
                              backgroundColor: !formik.dirty
                                ? "#777"
                                : "#1565d8",
                              color: "#fff",
                              padding: uploader ? "7px 40px" : "10px 15px",
                              // width: "150px",
                              textTransform: "initial",
                              marginRight: "10px",
                            }}
                          >
                            {uploader ? (
                              <div
                                className="spinner-border text-light"
                                // style={{}}
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Update/Save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            // newestOnTop={false}
            closeOnClick
            rtl={false}
          />
          <MyVerticallyCenteredModal
            editUser={editUser}
            initialValues={initialValues}
            setRefresh={setRefresh}
            handleModal={props.handleModal}
            setEdit={setEdit}
            // modalStatus={props.modalStatus}
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
          />
          <section className="filter-section testreport">
            <div className="sectionHeader p-3">
              <h2 className="hkBoldFont" style={{ color: "#161616" }}>
                Users
              </h2>
              <p className="mediumFont mt-2" style={{ color: "#515151" }}>
                {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. */}
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end  flex-md-row  mr-0 pt-2 px-0 pb-0">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  //   border: showFilter ? "1px solid #c3cad9" : 0,
                  borderLeft: "none",
                  borderBottom: "none",
                }}
                className="search__testcontainer"
                // id={open ? "searchTransition" : "searchStatic"}
              >
                <input
                  type="text"
                  name="search"
                  id={"search__input"}
                  placeholder="Search user"
                />
              </div>
              {/* {} */}
              {userAccess &&
              _.some(userAccess.privileges, ["privilege_name", "ADD USERS"]) ? (
                <Button
                  variant="contained"
                  color="secondary"
                  // className="primary__button
                  type="submit"
                  startIcon={
                    <IconContext.Provider
                      value={{
                        color: "#fff",
                        size: "25px",
                      }}
                    >
                      <MdAdd />
                    </IconContext.Provider>
                  }
                  style={{ width: "250px" }}
                  className={[classes.rootAbsolute, "primary__button"]}
                  onClick={() => {
                    setEdit({
                      data: "",
                      index: "",
                    });
                    setShowEditModal(true);
                  }}
                >
                  Add User
                </Button>
              ) : null}
            </div>
          </section>
          <section
            className="admin-product-filter-section table-responsive fixHeader
"
          >
            <table
              className="table  text-center  admin-product_usertable mb-0"
              style={{
                backgroundColor: "white",
              }}
              id="bill-table"
            >
              <thead>
                <tr
                  id="admin-product-row"
                  style={{ border: "none", backgroundColor: "#E0E0E0" }}
                >
                  <th scope="col" className="admin-product-row_table-header">
                    ID
                  </th>
                  <th scope="col" className="admin-product-row-table-header">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Email
                  </th>

                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Verification
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Created At
                  </th>

                  {userAccess &&
                  _.some(userAccess.privileges, [
                    "privilege_name",
                    "EDIT USERS",
                  ]) ? (
                    <th
                      scope="col"
                      className="admin-product-row-action-col admin-product-row-table-header"
                    ></th>
                  ) : null}
                  <th
                    scope="col"
                    className="admin-product-row-action-col admin-product-row-table-header"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {userData.length ? (
                  userData.map((data, i) => {
                    if (i > rowsPerPage - 1) {
                      return;
                    }
                    const color = data.user_status === 1 ? "#03A33B" : "red";

                    //   const borderLeft = `5px solid ${color}`;
                    const borderBottom = `1px solid #B3CDFF`;

                    return (
                      <>
                        <tr
                          key={data.user_id + Math.random()}
                          style={{
                            //   borderLeft,
                            borderBottom,
                            backgroundColor: "white",
                          }}
                          className="admin-product-row-table-row-hover "
                        >
                          <td
                            scope="row"
                            id="admin-product-rd"
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            <p
                              data-toggle="collapse"
                              data-target={`#accordion` + i}
                              className="clickable"
                              id="button-open"
                              // style={{ display: "none" }}
                            >
                              <IconContext.Provider
                                value={{
                                  color: "#4059E2",
                                  size: "25px",
                                  style: { marginBottom: "3px" },
                                }}
                              >
                                <IoMdAddCircle className="toggle-row-icon" />
                              </IconContext.Provider>
                            </p>{" "}
                            UID{data.user_id}
                          </td>
                          <td
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            {data.first_name} {data.last_name}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.email_id}
                          </td>

                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{ color, ...rowdataAlign }}
                          >
                            {data.user_status ? "Active" : "Inactive"}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{
                              color: data.completed ? "#03A33B" : "#ffb100",
                              ...rowdataAlign,
                            }}
                          >
                            {data.completed ? "Verified" : "Pending"}
                          </td>

                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {moment(data.created_date).format(
                              "YYYY-MM-DD hh:mm A"
                            )}
                          </td>

                          {userAccess &&
                          _.some(userAccess.privileges, [
                            "privilege_name",
                            "EDIT USERS",
                          ]) ? (
                            <td
                              className="admin-product-row-action-col admin-product-row-table-row-data "
                              style={rowdataAlign}
                            >
                              <Button
                                variant="contained"
                                color="default"
                                className={"admin-product-Filter__button mr-2"}
                                // startIcon={<AiOutlineCloudDownload color="#2C3E50" />}
                                style={{ padding: "10px 20px" }}
                                onClick={() => {
                                  getEditData(data, i);

                                  setShowEditModal(true);
                                }}
                                startIcon={<BiEditAlt />}
                              >
                                Edit
                              </Button>
                            </td>
                          ) : null}
                          <td
                            className="admin-product-row-action-col admin-product-row-table-row-data "
                            style={rowdataAlign}
                          >
                            <Button
                              variant="contained"
                              color="default"
                              className={"admin-product-Filter__button mr-2"}
                              // startIcon={<AiOutlineCloudDownload color="#2C3E50" />}
                              style={{ padding: "10px 20px" }}
                              onClick={() => {
                                history.push({
                                  pathname: "/admin/user-view",
                                  state: {
                                    userData: data,
                                  },
                                });
                              }}
                              startIcon={<BiEditAlt />}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                        <tr
                          id={"accordion" + i}
                          className="collapse  admin-product-hided-row"
                          key={data.user_id + Math.random()}
                        >
                          <td colSpan="6" style={rowdataAlign}>
                            <ul
                              className="admin-product-row-table-hide-list text-left mediumFont"
                              style={{ color: "#525252" }}
                            >
                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Email
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.email_id}
                                  </div>
                                </div>
                              </li>

                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Status:
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{ color }}
                                  >
                                    {data.user_status ? "Active" : "Inactive"}
                                  </div>
                                </div>
                              </li>
                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Verification:
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{
                                      color: data.completed
                                        ? "#03A33B"
                                        : "#ffb100",
                                    }}
                                  >
                                    {data.completed ? "Verified" : "Pending"}
                                  </div>
                                </div>
                              </li>

                              {userAccess &&
                              _.some(userAccess.privileges, [
                                "privilege_name",
                                "EDIT USERS",
                              ]) ? (
                                <li
                                  style={{
                                    // borderBottom: "1px solid #B3CDFF",
                                    padding: "10px",
                                  }}
                                >
                                  <div className="row justify-content-center mt-2 ml-3">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center">
                                      <Button
                                        variant="contained"
                                        color="default"
                                        className={
                                          "admin-product-Filter__button mr-2"
                                        }
                                        // startIcon={
                                        //   <AiOutlineCloudDownload color="#2C3E50" />
                                        // }
                                        style={{ padding: "10px 33px" }}
                                        onClick={() => {
                                          getEditData(data, i);

                                          setShowEditModal(true);
                                        }}
                                        startIcon={<BiEditAlt />}
                                        // onClick={() => {
                                        //   setBillView({ isView: true, data });
                                        // }}
                                      >
                                        Edit
                                      </Button>
                                    </div>
                                  </div>
                                </li>
                              ) : null}

                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-center mt-2 ml-3">
                                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center">
                                    <Button
                                      variant="contained"
                                      color="default"
                                      className={
                                        "admin-product-Filter__button mr-2"
                                      }
                                      // startIcon={
                                      //   <AiOutlineCloudDownload color="#2C3E50" />
                                      // }
                                      style={{ padding: "10px 33px" }}
                                      onClick={() => {
                                        history.push({
                                          pathname: "/admin/user-view",
                                          state: {
                                            userData: data,
                                          },
                                        });
                                      }}
                                      startIcon={<BiEditAlt />}
                                      // onClick={() => {
                                      //   setBillView({ isView: true, data });
                                      // }}
                                    >
                                      View
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <div
                    className="center__items p-5"
                    style={{ flexDirection: "column" }}
                  >
                    <img src={emptyUser} alt="empty-user" />
                    <p
                      style={{
                        color: "#D2D2D2",
                        padding: "20px",
                        fontSize: "20px",
                        fontFamily: "mediumFont",
                      }}
                    >
                      {userAccess &&
                      _.some(userAccess.privileges, [
                        "privilege_name",
                        "VIEW USERS",
                      ])
                        ? "Users not found..."
                        : "You don't have access"}
                    </p>
                  </div>
                )}
              </tbody>
            </table>
          </section>
          {allUsers.length > 5 || page > 0 ? (
            <div className="d-flex flex-row align-items-center justify-content-end mb-4">
              <TablePagination
                component="div"
                count={allUsers.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 50]}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Users;
